<template>
  <li class="nav-item mb-2">
    <a :href="href" class="nav-link p-0 text-white">
      {{ text }}
    </a>
  </li>
</template>

<script>
export default {
  props: {
    href: String,
    text: String,
  },
}
</script>
