<template>
  <div class="px-2">
    <h5 class="text-white fs-4">{{ title }}</h5>
    <ul class="nav flex-column">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>
