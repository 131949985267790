<template>
  <slot></slot>
  <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-12">
    <div class="form-group col-span-12">
      <label for="maloai" class="block text-sm font-medium text-white mb-2"
        >Chọn danh mục <span v-show="!maloai" class="error">*</span></label
      >
      <select
        v-model="maloai"
        id="maloai"
        name="maloai"
        required
        class="text-white bg-black placeholder:text-white w-full"
        placeholder="Chọn danh mục"
      >
        <option disabled selected value>Chọn danh mục</option>
        <option
          v-for="category in categories"
          :key="category.id"
          :value="category.id"
          v-if="categories.length"
        >
          {{ category.ten_loai }}
        </option>
      </select>
    </div>
    <div class="form-group col-span-12">
      <label for="ProductName" class="block text-sm font-medium text-white mb-2"
        >Tên sản phẩm <span v-show="!tensp" class="error">*</span></label
      >
      <input
        v-model="tensp"
        type="text"
        name="productTitle"
        id="ProductName"
        autocomplete="off"
        class="text-white bg-black w-full"
        placeholder="Tên sản phẩm"
        required
      />
    </div>
    <div class="form-group col-span-12 sm:col-span-6">
      <label
        for="ProductImages"
        class="block text-sm font-medium text-white mb-2"
        >Hình ảnh <span v-show="!ProductImages" class="error">*</span></label
      >
      <FilePond
        aria-required="true"
        name="ProductImages"
        id="ProductImages"
        v-model="ProductImages"
        server="/api/images"
        ref="pond"
        label-idle="Drop files here..."
        accept="image/*"
      >
      </FilePond>
    </div>
    <div class="form-group col-span-12">
      <MultiImages />
    </div>

    <div class="form-group col-span-12">
      <label for="price" class="block text-sm font-medium text-white mb-2"
        >Nhập giá <span v-show="!giasp" class="error">*</span></label
      >
      <input
        v-model="giasp"
        type="number"
        name="price"
        id="price"
        class="text-white bg-black w-full"
        placeholder="Giá"
        required
      />
    </div>

    <div
      class="form-group col-span-12"
      v-for="(account, index) in accounts"
      :key="index"
    >
      <label
        :for="'tkgame-' + index"
        class="block text-sm font-medium text-white mb-2"
        >Tài khoản {{ index + 1 }}
        <span v-show="!account.tkgame" class="error">*</span></label
      >
      <input
        v-model="account.tkgame"
        type="text"
        :name="'accounts[' + index + '][tkgame]'"
        :id="'tkgame-' + index"
        class="text-white bg-black w-full"
        placeholder="Tài khoản account"
        required
      />

      <label
        :for="'mkgame-' + index"
        class="block text-sm font-medium text-white mb-2"
        >Mật khẩu {{ index + 1 }}
        <span v-show="!account.mkgame" class="error">*</span></label
      >
      <input
        v-model="account.mkgame"
        type="text"
        :name="'accounts[' + index + '][mkgame]'"
        :id="'mkgame-' + index"
        class="text-white bg-black w-full"
        placeholder="Mật khẩu account"
        required
      />

      <button
        type="button"
        class="btn btn-outline-danger"
        @click="removeAccount(index)"
        v-show="accounts.length > 1"
      >
        <span class="fa-solid fa-trash"></span> Xóa acc
      </button>
    </div>
    <!-- End dynamic input -->
    <div class="form-group col-span-12">
      <label for="notes" class="block text-sm font-medium text-white mb-2"
        >Lưu ý</label
      >
      <input
        type="text"
        name="notes"
        id="notes"
        class="text-white bg-black w-full"
        placeholder="lưu ý sản phẩm"
      />
    </div>
    <div class="form-group col-span-12">
      <label for="desription" class="block text-sm font-medium text-white mb-2"
        >Mô tả <span v-show="!mota" class="error">*</span></label
      >
      <!--  <textarea
        v-model="mota"
        name="desription"
        id="desription"
        rows="3"
        class="w-full text-white bg-black"
        placeholder="Mô tả"
        required
      ></textarea> -->
      <RichEditor name="desription" title="Mô tả " />
    </div>
    <div class="flex w-full gap-4 col-span-8">
      <button
        type="submit"
        @click="CheckListImg('Frmadd')"
        class="p-3 bg-green-300 text-black"
        name="themsp"
        id="submit-btn"
      >
        <span class="fa-solid fa-file-arrow-down"></span> Thêm
      </button>
      <!-- Add button "Add" to duplicate inputs -->
      <button
        type="button"
        class="p-3 bg-green-300 text-black"
        @click="addAccount"
      >
        <span class="fa-solid fa-plus"></span> Thêm acc
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import route from 'ziggy-js'

const props = defineProps(['title', 'categories'])
const isShowModal = ref(false)

const maloai = ref('')
const tensp = ref('')
const ProductImages = ref(null)
const images = ref(null)
const giasp = ref('')
const accounts = ref([{ tkgame: '', mkgame: '' }])
const mota = ref('')

const addAccount = () => {
  accounts.value.push({ tkgame: '', mkgame: '' })
}

const removeAccount = (index) => {
  accounts.value.splice(index, 1)
}

const openModal = () => {
  isShowModal.value = true
}

const closeModal = () => {
  isShowModal.value = false
}
</script>
<style>
.error {
  color: red;
}
</style>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import MultiImages from './MultiImages.vue'
import RichEditor from '../../../components/RichEditor.vue'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
</script>

<style scoped>
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
</style>
