<template>
  <div :class="['grid  gap-4 mb-4', gridColumn()]">
    <div
      v-for="category in categoryhome"
      :key="category.id"
      :class="[' relative overflow-hidden w-auto rounded-lg box-border', width]"
      style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    >
      <div class="flex flex-col relative">
        <a :href="'/sanpham/' + category.id" class="block">
          <img
            :src="'/' + isImagePath(category.hinh_loai)"
            class="rounded-lg h-28 opacity-60 w-full"
            :alt="category.ten_loai"
          />
        </a>
        <a
          :href="'/sanpham/' + category.id"
          class="text-center pt-0 absolute h-full w-full"
        >
          <div class="block w-full bottom-0 text-center absolute bg-black/30">
            <h5
              class="font-bold mt-1 mb-3 text-white text-base capitalize font-['Inter']"
              style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              "
            >
              {{ category.ten_loai }}
            </h5>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'

const props = defineProps(['categoryhome', 'column', 'width'])

const categoryHome = ref(props.categoryhome)
const showAllCategories = ref(false)
const initialRowCount = 12
const categories = ref([])

const gridColumn = () => {
  switch (props.column) {
    case '6':
      return 'grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6'
    default:
      return 'grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5'
  }
}

const toggleShowAll = () => {
  showAllCategories.value = !showAllCategories.value
  categories.value = showAllCategories.value
    ? categoryHome.value
    : categoryHome.value.slice(0, initialRowCount)
}
const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  return 'storage/' + path
}
onMounted(() => {
  categories.value = categoryHome.value.slice(0, initialRowCount)
})
</script>
