<script setup>
import { ref, computed } from 'vue'
import route from 'ziggy-js'

const props = defineProps({
  category_id: {
    type: Number,
    required: true,
  },
  filter: {
    type: Object,
    required: true,
  },
})

var params = ''

const sortBy = ref(props.filter.sort_by || '')

function sortProducts() {
  const sortParam = sortBy.value || ''

  params = route('product', {
    categoryID: props.category_id,
    sort_by: sortParam,
  })
}

const sortedOptions = computed(() => {
  const options = [
    { value: '', text: 'Sắp xếp theo giá' },
    { value: 'price_asc', text: 'Giá tăng dần' },
    { value: 'price_desc', text: 'Giá giảm dần' },
  ]
  return options.sort((a, b) =>
    a.value === sortBy.value ? -1 : b.value === sortBy.value ? 1 : 0
  )
})
</script>

<template>
  <form :action="params" method="get" class="flex gap-2">
    <select
      v-model="sortBy"
      @change="sortProducts"
      name="sort_price"
      id="sort_price"
      class="rounded"
    >
      <option
        v-for="option in sortedOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <button
      class="bg-green-400 p-3 text-white rounded text-xl font-normal tracking-[-1%]"
    >
      Tìm Kiếm
    </button>
  </form>
</template>
