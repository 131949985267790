<template>
  <div class="mb-5">
    <div
      v-for="(image, index) in images"
      :key="index"
      class="flex flex-col gap-3 mb-5"
    >
      <label :for="'iamges-' + index"
        >Hình sản phẩm {{ index + 1 }}
        <span v-show="!image" class="error">*</span></label
      >
      <FilePond
        aria-required="true"
        :ref="'pond' + index"
        :name="'images[' + index + ']'"
        label-idle="Drop files here..."
        accept="image/*"
        server="/api/images-multi"
        @update:files="updateFiles($event, index)"
      >
      </FilePond>
    </div>
    <button
      type="button"
      @click="addImage"
      class="p-3 bg-green-300 text-black rounded"
      name="themsp"
      id="submit-btn"
    >
      <span class="fa-solid fa-file-arrow-down"></span> Thêm hình phụ sản phẩm
    </button>
  </div>
</template>

<script setup="ts">
import { ref } from 'vue'

const images = ref([''])

const addImage = () => {
  images.value.push('')
}
</script>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
</script>

<style scoped>
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
</style>
