<template>
  <button class="h-16 sm:h-10 p-3 rounded mt-2 overflow-hidden">
    <div>
      <slot></slot>
    </div>
    <div class="container-stars">
      <div class="circle-starts"></div>
    </div>
  </button>
</template>
<style>
.circle-starts {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

.circle-starts::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

.circle-starts::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

.circle-starts::after {
  content: '';
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}
</style>
